<template>
  <el-input :size="size" v-model="value"></el-input>
</template>

<script>
  export default {
    name: 'StringField',
    props: {
      value: {
        type: String,
        default: null
      },
      size: {
        type: String,
        default: 'small'
      }
    }
  }
</script>

<style scoped>

</style>
